import { Button, DialogContent, Dialog, Link, Picture, DialogTrigger } from '@troon/ui';
import { Show, For, Suspense, lazy, createMemo, createSignal } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { gql } from '../graphql';
import { dayTimeToDate, dayToDayJs, timeFrameNameFromHours } from '../modules/date-formatting';
import type { CalendarDay, DayFragment, Facility, TeeTimeAlertPreviewFragment, TimeFragment } from '../graphql';

type Props = {
	editable?: boolean;
	teeTimeAlert: TeeTimeAlertPreviewFragment;
	level?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const EditTeeTimeAlert = lazy(() => import('./tee-time-alert-edit'));

export function TeeTimeAlert(props: Props) {
	const [editOpen, setEditOpen] = createSignal(false);

	const reserveParams = new URLSearchParams();
	const searchParams = new URLSearchParams({
		startAt: `${props.teeTimeAlert.facilityTrigger.startAt?.hour ?? 0}`,
		endAt: `${props.teeTimeAlert.facilityTrigger.endAt?.hour ?? 24}`,
		year: `${(props.teeTimeAlert.facilityTrigger.day as CalendarDay).year}`,
		month: `${(props.teeTimeAlert.facilityTrigger.day as CalendarDay).month}`,
		day: `${(props.teeTimeAlert.facilityTrigger.day as CalendarDay).day}`,
		courseIds: props.teeTimeAlert.facilityTrigger.courseIds.join(','),
	});
	if (props.teeTimeAlert.facilityTrigger.players) {
		reserveParams.set('players', `${props.teeTimeAlert.facilityTrigger.players}`);
		searchParams.set('players', `${props.teeTimeAlert.facilityTrigger.players}`);
	}

	const alertDate = createMemo(() =>
		dayToDayJs(
			props.teeTimeAlert.facilityTrigger.day as CalendarDay,
			props.teeTimeAlert.facilityTrigger.facility.timezone,
		).toDate(),
	);

	return (
		<div class="flex flex-row flex-wrap items-stretch gap-4 @container @lg:flex-nowrap">
			<div class="w-full grow self-stretch @lg:grow-0 @lg:basis-48">
				<Picture
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="aspect-[8/3] w-full rounded bg-neutral-500 object-cover @lg:aspect-[4/3]"
					src={props.teeTimeAlert.facilityTrigger.facility.metadata?.hero?.url}
					alt={props.teeTimeAlert.facilityTrigger.facility.name}
					sizes={[[400, 300]]}
					width="400"
					height="145"
				/>
			</div>
			<div class="flex grow flex-col justify-between gap-2 self-stretch">
				<div class="flex shrink-0 grow flex-row justify-between gap-4">
					<div>
						<Dynamic component={props.level ?? 'h2'} class="text-lg font-semibold">
							{props.teeTimeAlert.facilityTrigger.facility.name}
						</Dynamic>
						<p class="text-sm text-neutral-800">
							<time class="block">
								{dayjs.tz(alertDate(), props.teeTimeAlert.facilityTrigger.facility.timezone).format('dddd, MMMM D')} •{' '}
								{timeFrameNameFromHours(
									props.teeTimeAlert.facilityTrigger.startAt?.hour ?? 0,
									props.teeTimeAlert.facilityTrigger.endAt?.hour ?? 24,
									true,
								)}
							</time>
							<Show when={props.teeTimeAlert.facilityTrigger.players}>
								{(players) => (
									<>
										<Icon name="users" /> {players()} player
										{players() > 1 ? 's' : ''}
									</>
								)}
							</Show>
						</p>
					</div>
					<div class="flex flex-wrap items-stretch gap-2 self-start @lg:self-end">
						<Show when={props.teeTimeAlert.facilityTrigger.courseTeeTimesPreview?.hasMore}>
							<Button
								class="hidden shrink @lg:block"
								appearance="secondary"
								as={Link}
								href={`/course/${props.teeTimeAlert.facilityTrigger.facility.slug}/reserve-tee-time/?${searchParams.toString()}`}
							>
								View all
							</Button>
						</Show>
						<Show when={props.editable}>
							<Suspense>
								<Dialog key="edit-tee-time-alert" open={editOpen()} onOpenChange={setEditOpen}>
									<DialogTrigger appearance="secondary">
										<Icon name="edit-pencil-01" />
										<span class="sr-only">
											Edit alert for{' '}
											{dayjs
												.tz(alertDate(), props.teeTimeAlert.facilityTrigger.facility.timezone)
												.format('dddd, MMMM D')}{' '}
											•{' '}
											{timeFrameNameFromHours(
												props.teeTimeAlert.facilityTrigger.startAt?.hour ?? 0,
												props.teeTimeAlert.facilityTrigger.endAt?.hour ?? 24,
												true,
											)}
										</span>
									</DialogTrigger>
									<DialogContent header="Edit tee time alert" headerLevel="h3">
										<EditTeeTimeAlert
											id={props.teeTimeAlert.id}
											onComplete={() => setEditOpen(false)}
											facility={props.teeTimeAlert.facilityTrigger.facility as Facility}
											selectedDay={alertDate()}
											selectedTime={[
												props.teeTimeAlert.facilityTrigger.startAt?.hour ?? 0,
												props.teeTimeAlert.facilityTrigger.endAt?.hour ?? 24,
											]}
											selectedPlayers={props.teeTimeAlert.facilityTrigger.players ?? -1}
										/>
									</DialogContent>
								</Dialog>
							</Suspense>
						</Show>
					</div>
				</div>
				<ul class="flex shrink flex-row gap-2">
					<For each={props.teeTimeAlert.facilityTrigger.courseTeeTimesPreview?.teeTimes}>
						{(teeTime) => (
							<li class="shrink">
								<Button
									as={Link}
									href={`/course/${props.teeTimeAlert.facilityTrigger.facility.slug}/reserve-tee-time/${teeTime.id}/?${reserveParams.toString()}`}
								>
									{dayjs
										.tz(
											dayTimeToDate({
												day: props.teeTimeAlert.facilityTrigger.day as DayFragment,
												time: teeTime.dayTime.time as TimeFragment,
											}),
											props.teeTimeAlert.facilityTrigger.facility.timezone,
										)
										.format('h:mm A')}
								</Button>
							</li>
						)}
					</For>
					<Show when={props.teeTimeAlert.facilityTrigger.courseTeeTimesPreview?.hasMore}>
						<li class="shrink @lg:hidden">
							<Button
								appearance="transparent"
								as={Link}
								href={`/course/${props.teeTimeAlert.facilityTrigger.facility.slug}/reserve-tee-time/?${searchParams.toString()}`}
							>
								+ more
							</Button>
						</li>
					</Show>
				</ul>
			</div>
		</div>
	);
}

gql(`
fragment TeeTimeAlertPreview on TeeTimeAlert {
  id
	state
	pushEnabled
	emailEnabled
	facilityTrigger {
    courseIds
		day {
			...Day
		}
		startAt {
			hour
		}
		endAt {
			hour
		}
		players
		facility {
			name
			slug
      timezone
			metadata {
				hero {
					url
				}
			}
			courses {
				id
				name
				bookingWindowDay {
					...Day
				}
			}
		}
		courseTeeTimesPreview {
			hasMore
			teeTimes {
        id
				dayTime {
					time {
						...Time
					}
				}
			}
		}
	}
}`);
